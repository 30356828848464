/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

*{
    font-family: 'Montserrat', sans-serif;    

}

html,
body {
    margin: 0;
    overflow: overlay;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(177, 175, 175);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(131, 129, 129);
}

.btn{
    width: 250px;
    height: 50px;
}
